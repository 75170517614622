var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(
          _vm.allows_crud &&
          ((_vm.user_position &&
            [1].includes(_vm.user_position.position) &&
            [1].includes(_vm.user.groups[0])) ||
            _vm.user.is_superuser ||
            _vm.$hasObjectPermission('mesh.faculty', 'add'))
        )?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_faculty'),expression:"'mesh.add_faculty'"},{name:"b-modal",rawName:"v-b-modal.new-faculty-modal",modifiers:{"new-faculty-modal":true}}],staticClass:"btn btn-secondary btn-sm"},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("mesh.faculty", false, "Facultad"))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.faculty", true, "Facultades"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.faculty',"allows_crud":_vm.allows_crud}})],1),(_vm.facultyList)?_c('GenericBTable',{attrs:{"items":_vm.facultyList,"filterCustom":_vm.filterCustom,"pagination":_vm.facultyList.length,"fields":_vm.fields_faculties,"primary-key":"id","filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(campus)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getCampusName(row.item.campuses)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},{key:"cell(color)",fn:function(row){return [_c('div',{staticClass:"faculty-color",style:({
          'background-color': row.item.color,
          color: row.item.color,
        })})]}},{key:"cell(faculty_area)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.faculty_areas.filter(
            (x) => x.faculty == row.item.id
          )),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},{key:"cell(actions)",fn:function(row){return [(
          _vm.allows_crud &&
          ((_vm.user_position &&
            [1].includes(_vm.user_position.position) &&
            [1].includes(_vm.user.groups[0])) ||
            _vm.user.is_superuser ||
            _vm.$hasObjectPermission('mesh.faculty', 'change', row.item.id))
        )?[_c('b-modal',{attrs:{"id":`edit-faculty-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.faculty',
            false,
            'Facultad'
          )}`,"size":"lg","hide-footer":""}},[_c('FacultyForm',{attrs:{"Faculty":row.item,"show_title":false},on:{"createdArea":_vm.slotCreatedArea,"updated":_vm.slotUpdatedFaculty,"updatedArea":_vm.slotUpdatedArea,"deletedArea":_vm.slotDeletedArea}})],1),_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_faculty'),expression:"'mesh.change_faculty'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:({
            customClass: 'custom-tooltip',
          }),expression:"{\n            customClass: 'custom-tooltip',\n          }",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"title":`Editar ${_vm.$getVisibleNames(
            'mesh.faculty',
            false,
            'Facultad'
          )}`},on:{"click":function($event){return _vm.$bvModal.show(`edit-faculty-modal-${row.item.id}`)}}}),(_vm.isValidDelete(row.item.id))?_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_faculty'),expression:"'mesh.delete_faculty'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:({
            customClass: 'custom-tooltip',
          }),expression:"{\n            customClass: 'custom-tooltip',\n          }",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"title":`Eliminar ${_vm.$getVisibleNames(
            'mesh.faculty',
            false,
            'Facultad'
          )}`},on:{"click":function($event){return _vm.askForDeleteFaculty(row.item.id)}}}):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
            `No se puede eliminar la ${_vm.$getVisibleNames(
              'mesh.faculty',
              false,
              'Facultad'
            )} si cuenta con ${_vm.$getVisibleNames(
              'mesh.career',
              true,
              'Programas'
            )} asignado`
          ),expression:"\n            `No se puede eliminar la ${$getVisibleNames(\n              'mesh.faculty',\n              false,\n              'Facultad'\n            )} si cuenta con ${$getVisibleNames(\n              'mesh.career',\n              true,\n              'Programas'\n            )} asignado`\n          ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-delete',{attrs:{"disabled":""}})],1)]:_vm._e()]}}],null,false,354608539)}):_vm._e(),_c('div',[_c('b-modal',{attrs:{"id":"new-faculty-modal","title":`Crear ${_vm.$getVisibleNames('mesh.faculty', false, 'Facultad')}`,"size":"lg","hide-footer":""}},[_c('FacultyForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedFaculty,"createdAreas":_vm.slotCreatedAreas}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }